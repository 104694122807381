<template>
  <g>
    <svg:style>
      .cls-5 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-5 {
        stroke-linecap: square;
        fill: url(#glass-pattern);
      }
    </svg:style>
    <g id="L24">
      <line id="impost"
            v-if="showImpost"
            class="line"
            :x1="doorLeftWidth1 + 57.79"
            :y1="inoxY"
            :x2="doorLeftWidth1 + 57.79"
            :y2="inoxHeight"/>
      <line id="impost-2"
            v-if="showImpost"
            data-name="impost"
            class="line"
            :x1="doorLeftWidth1 + 45.7"
            :y1="inoxHeight"
            :x2="doorLeftWidth1 + 45.7"
            :y2="inoxY"/>
      <polygon id="glass"
               class="cls-5"
               :points="`
               ${doorLeftWidth1 + 57.8} ${inoxHeight}
               ${doorLeftWidth1 + 81.98} ${inoxHeight}
               ${doorLeftWidth1 + 81.98} ${inoxY}
               ${doorLeftWidth1 + 57.8} ${inoxY}
               ${doorLeftWidth1 + 57.8} ${inoxHeight}
               ${doorLeftWidth1 + 57.8} ${inoxHeight}
               `"
      />
      <line id="impost-2-2"
            v-if="showImpost"
            data-name="impost-2"
            class="line"
            :x1="doorLeftWidth1 + 93.14"
            :y1="inoxY"
            :x2="doorLeftWidth1 + 93.14"
            :y2="inoxHeight"/>
      <line id="impost-2-3"
            v-if="showImpost"
            data-name="impost-2"
            class="line"
            :x1="doorLeftWidth1 + 81.98"
            :y1="inoxHeight"
            :x2="doorLeftWidth1 + 81.98"
            :y2="inoxY"/>
    </g>
  </g>
</template>


<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
    'showVariant',
    'laminationId',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
      leftOffset: 81,
    }
  },
  computed: {
    showImpost() {
      return this.showBg;
    },
    inoxY() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leafOffset
    },
    inoxH() {
      return this.showBg ?
        this.doorHeight - this.inoxOffset * 2 :
        this.doorHeight - this.leafOffset * 2
    },
    inoxW() {
      return this.showBg ?
        this.doorWidth - this.inoxOffset - this.leftOffset / 2 :
        this.doorWidth - this.leafOffset - this.leftOffset / 2
    },
    inoxTop() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leafOffset
    },
    inoxHeight() {
      return this.inoxY + this.inoxH;
    },
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
  },
}
</script>
